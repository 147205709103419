
@font-face {
	font-family: 'BNR'; 
	src: url(../src/fonts/bnr.ttf); 
}

.logoheader {
    background: url(../src/img/logo.svg) no-repeat center center, #5c7e8a;
    padding: 11px 8px 11px 8px;
    margin: 11px;
    background-size: contain;
}

.ant-input-affix-wrapper {
    border-radius: 50px !important;
}

.headerfull {
    -webkit-box-shadow: 0px 4px 30px 0px rgb(0 0 0 / 20%);
    box-shadow: 0px 4px 30px 0px rgb(0 0 0 / 20%);
    background: #5c7e8a;
}

.flexcenter {
    display: flex;
    justify-content: center;
}

.logo {
    height: 40px;
    background-size: contain;
}

.ant-menu-horizontal {
    display: flex;
    justify-content: center;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: #001a29;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #002438;
}

.site-layout-sub-header-background {
    background: #fff;
}

.site-layout-background {
    background: transparent;
    border-radius: 30px;
    min-height: 200px;
}

.cardnone {
    background: none!important;
    box-shadow: none!important;
}

.bordershadow {
    padding: 30px;
    border-radius: 30px;
    background: #ffffff;
    box-shadow: 0 4px 30px 0 rgb(0 0 0 / 20%);
}

.ant-form-item:last-child {
    margin-bottom: 0;
}

.wrow {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}

.nomargin {
    margin:0 !important
}

.wcol {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    padding: 20px;
    min-width: 300px;
    flex: 1 1 calc(100%/4 - 4rem);
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
}

.wcol > .ant-badge {
    height: 100%;
}

.maxtask {
    margin-top: -20px;
}

.max400 {
    max-width: 400px !important;
    margin-left: auto;
    margin-right: auto;
}

.colors {
    display: flex;
    flex-wrap: wrap;
    gap: 1px;
    max-width: 150px;
}

.colors > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 calc(100%/4 - 2px);
    margin: 5px 0;
}

.maxtask:before{
    content:"";
    display:block;
    height:30px;
    position:relative;
    top:180px;
    background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #f7f7f7 75%)
}

.c1.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #f7f7f7 75%)}
.c2.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffc9c5 75%)}
.c3.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffe087 75%)}
.c4.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #fffbcb 75%)}
.c5.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #e4ffc4 75%)}
.c6.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #d7fff6 75%)}
.c7.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #d8f8ff 75%)}
.c8.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #c7dcff 75%)}
.c9.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ead2ff 75%)}
.c10.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffecf6 75%)}
.c11.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffedd8 75%)}
.c12.maxtask:before {background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), #e8eaed 75%)}

.ant-layout, .ant-layout * {
    overflow: visible;
}

.lasttask {
    margin-top: auto;
    margin-bottom: 0 !important;
}

.maxtask {
    max-height: 200px;
    overflow: hidden;
}

.theme {
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.elementlock::before {
    content: "";
    height: 100%;
    width: 100%;
    background: transparent;
    position: absolute;
    z-index: 2;
}

.elementlock ~ * {
    z-index: 3;
}

.ant-table {
    background: transparent;
}

.taskhide {
    cursor: pointer;
    transition: all .3s ease;
}

.ant-card-meta-title {
    white-space: pre-wrap;
}

.ant-image-img {
    object-fit: contain;
}

.filelist .ant-col, .filelist .ant-col, .filelist .ant-col > .ant-list-item, .filelist .ant-col > .ant-list-item > .ant-card {
    height: 100%;
}

.filelist .ant-row {
    justify-content: center;
    align-items: stretch;
}

.badgemenu {
    margin-right: 5px;
}

.filelist .ant-row > div {
    margin-bottom: 20px;
}

.animdown > div {
    animation: carding 1s ease;
}

.taskhide:hover {
    -webkit-box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
    transform: scale(1.01);
}

.ant-popover-inner {
    border-radius: 15px;
}

.ant-alert {
    border-radius: 20px !important;
}

.fcenter {
    display: flex;
    justify-content: center;
}

.shadowscreen {
    -webkit-animation: shadowscreen 3s infinite ease-in-out;
    animation: shadowscreen 3s infinite ease-in-out;
    background: #f7f7f7;
    border-radius: 30px;
}

@keyframes shadowscreen {
    0% {
        -webkit-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
    }
    50% {
        -webkit-box-shadow: 0px 4px 30px 10px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 4px 30px 10px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 4px 30px 10px rgba(0, 0, 0, 0.2);
    }
    100% {
        -webkit-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.2);
    }
}

[data-theme="dark"] .site-layout-sub-header-background {
    background: #141414;
}

.login-form-button {
    width: 100%;
}

.authform {
    justify-content: center;
}

.ant-avatar > img, .ant-avatar .ant-image-img {
    border-radius: 50%;
}

.authpage {
    width: 100%;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loadpage {
    justify-content: center;
}

.bg-auth {
    background: url(../src/img/bg.png) fixed no-repeat center center;
    background: #26272f !important;
    background-size: cover !important;
}

.buttmar > button {
    margin-right: 8px;
    margin-bottom: 12px;
}

@media screen and (max-width: 700px) {
    .wcol:last-child:first-child {
        max-width: 100%;
    }
}

@media screen and (max-width: 650px) {

    .bg-auth {
        background: url(../src/img/bgm.png) fixed no-repeat center center;
    }
    
}

@media screen and (max-width: 600px) {

    .usercard {
        flex-direction: column !important;
    }

    .usercard > div:first-child {
        display: flex;
        justify-content: center;
    }

    .usercard > div {
        margin: 10px 0 !important;
    }
    
}

.gradient {
	color: transparent;    
	-webkit-background-clip: text;
	background-clip: text;
	background-image: linear-gradient(135deg, #0daaeb, #9054cb);
}

.auth_remember span {
    color: #FFFFFF !important;
    background-color: none !important;
}

.authpageblock {
    padding: 24px;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.authtitle {
    font-family: 'BNR';
    text-align: center;
    color: #FFFFFF;
}

.authtitle p:nth-child(2) {
    font-size: 35px;
    margin: 0 !important;
    padding: 0 !important;
}

.authtitle p:nth-child(3) {
    font-size: 60px;
    line-height: 40px;
}

.inauth {
    border-radius: 25px;
    height: 40px;
    color: #8a8a8a;
}

.inauths {
    border-radius: 25px;
}

.tableimg {
    max-width: 50px;
}

.tableimg > img {
    width: 100%;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: #000000;
}

::selection {
    background: #ff1818 !important;
}

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    border-radius: 50px;
}

.ant-pagination-item {
    border-radius: 50px;
}

.ant-pagination-item-active {
    border-color: #ff1818;
}

.ant-pagination-item-active:focus-visible, .ant-pagination-item-active:hover {
    border-color: #ff4040;
}

.ant-pagination-item-active:focus-visible a, .ant-pagination-item-active:hover a {
    color: #ff4040;
}

.ant-pagination-item-active a {
    color: #ff1818;
}

.ant-pagination-item:focus-visible, .ant-pagination-item:hover {
    border-color: #ff1818;
}

.ant-pagination-item:focus-visible a, .ant-pagination-item:hover a {
    color: #ff1818;
}

.ant-input:hover {
    border-color: #ff4040;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #ff4040;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #ff4040;
    box-shadow: 0 0 0 2px rgba(255, 24, 24, 0.2);
}

.ant-picker-range .ant-picker-active-bar {
    background: #ff1818;
}

.ant-menu-item:active, .ant-menu-submenu-title:active {
    background: #ffe6e6;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid #ff1818;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #ff1818;
}

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after, tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after, .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
    left: 6px;
    border-left: 1px dashed #ff7e7e;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
    border-top: 1px dashed #ff7e7e;
    border-bottom: 1px dashed #ff7e7e;
}

.ant-picker:hover, .ant-picker-focused {
    border-color: #ff4040;
}

.ant-picker-focused {
    box-shadow: 0 0 0 2px rgb(255 24 24 / 20%);
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before, .ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: #ffcbcb;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: #ffe6e6;
}

.ant-picker-focused {
    border-color: #ff4040;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 2px solid #ff3535;
}

.ant-btn-dashed:active {
    color: #d90909;
    background: #fff;
    border-color: #d90909;
}

.ant-btn-dashed:hover, .ant-btn-dashed:focus {
    color: #ff4040;
    background: #fff;
    border-color: #ff4040;
}

.ant-input:focus, .ant-input-focused {
    border-color: #ff4040;
    -webkit-box-shadow: 0 0 0 2px rgb(255 24 24 / 20%);
    box-shadow: 0 0 0 2px rgb(255 24 24 / 20%);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #ff4040;
}

.ant-input-affix-wrapper:hover {
    border-color: #ff4040;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color: #ff4040;
    -webkit-box-shadow: 0 0 0 2px rgba(255, 24, 24, 0.2);
    box-shadow: 0 0 0 2px rgba(255, 24, 24, 0.2);
}

.ant-radio-checked .ant-radio-inner {
    border-color: #ff1818;
}

.ant-radio-checked::after {
    border: 1px solid #ff1818;
}

.ant-radio-inner::after {
    background-color: #ff1818;
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: #ff1818;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ff1818;
    border-color: #ff1818;
}

.ant-checkbox-checked::after {
    border: 1px solid #ff1818;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #ff1818;
}

html {
    --antd-wave-shadow-color: #ff1818;
}

.bold {
    font-weight: 800;
}

.ant-input, .ant-input-affix-wrapper, .ant-input-number, .ant-modal-content, .ant-picker, .ant-popover-buttons button, .ant-select-multiple .ant-select-selector, .ant-select-single .ant-select-selector {
    border-radius: 30px!important;
}

.ant-modal-header {
    border-radius: 30px 30px 0 0;
}

.wowload {
    -webkit-animation: wowload 1s ease;
    animation: wowload 1s ease;
}

@keyframes wowload {
    0% {
       opacity: 0;
    }
    100% {
       opacity: 1;
    }
}

.column900 {
    align-items: center;
}

.column900 > .ant-col {
    width: 100%;
    max-width: 900px;
    margin-bottom: 24px;
}

.column900 > .ant-col:last-of-type {
    width: 100%;
    max-width: 900px;
    margin-bottom: 0;
}

html {
    --antd-wave-shadow-color: #ff1818;
}

.spincenter {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
}

.ant-select-dropdown {
    border-radius: 15px;
    padding: 0;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #ffbcbc;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  